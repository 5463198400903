<template>
    <div class="inner-container">
        <div class="section-title program"><i class="icon icon-binocular-graphic"></i><span>Стратегическое планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Целевые индикаторы</span></div>
        <div v-show="!edit">
            <!----------------Место для шапки с фильтром--------------->
            <div class="filter-container">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>

                        <!---------------Фильтр по году-------------------->
                        <div class="filter-block">
                            <b-form-group :label="yearItem" class="small">
                                <multiselect
                                    v-model="currentYear"
                                    :options="yearsArr"
                                    placeholder="Год"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadProgram"
                                />
                            </b-form-group>
                        </div>

                        <!---------------Документы СГП--------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[0].name_ru" class="small">
                                <multiselect
                                    v-model="dictProgramVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Документы СГП"
                                    :options="programArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadGoals"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>

                        <!---------------Цели--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[1].name_ru">
                                <multiselect
                                    v-model="dictProgramGoalVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Цели"
                                    :options="programGoalArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------АБП--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[2].name_ru">
                                <multiselect
                                    v-model="dictAbpVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="АБП"
                                    :options="abpArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-button variant="success" @click="chgParams">Открыть</b-button>
                        </div>
                    </div>
                </b-dropdown>
                <div class="filter-actions">
                    <b-button v-show="!edit" variant="primary" @click="addClk">
                        <i class="icon icon-plus-circle"></i>{{ addRecords.name_ru }}
                    </b-button>
                </div>
            </div>
<!--        </div>-->

        <!---------Начало грид таблицы-------------->
        <b-progress variant="success" v-show="bar<100" height="10px" :value="bar" striped animated></b-progress>
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr class="text-center">
                            <th rowspan="2">
                                <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                                    <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </th>
                            <th rowspan="2">СГП</th>
                            <th rowspan="2">Цели</th>
                            <th rowspan="2">Целевые индикаторы</th>
                            <th rowspan="2">АБП</th>
                            <th rowspan="2">БП</th>
                            <th rowspan="2">Ед. измерения</th>
                            <th colspan="2">Факт</th>
                            <th colspan="5">План</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th class="cellAlign">{{ this.tableFieldYearOne }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearTwo }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearThree }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearFour }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearFive }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearSix }}</th>
                            <th class="cellAlign">{{ this.tableFieldYearSeven }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(targetItem, targetItemIndx) of programObjArr">
                            <tr :key="'targetItemIndx'+targetItemIndx" class="table-info">
                                <td style="text-align: center">
                                    <button type="button" class="btn btn-secondary" @click="collapseItems(targetItemIndx, targetItem.id)">
                                        <i class="icon icon-chevron-circle" v-if="collapseArr[targetItemIndx]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                    </button>
                                </td>
                                <td colspan="5">{{ targetItem.name_ru }}</td>
                                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            </tr>
                            <template v-if="!collapseArr[targetItemIndx]">
                                <template v-for="(goalItem, goalItemIndex) of goalObjArr">
                                    <template v-if="goalItem!==null && goalItem.programId === targetItem.id">
                                        <tr :key="`targetItemIndx_${targetItemIndx}_${goalItemIndex}`" class="table-info">
                                            <td colspan="2" style="text-align: right">
                                                <button type="button" class="btn btn-secondary" @click="collapseItemsSec(goalItemIndex, targetItem.id)">
                                                    <i class="icon icon-chevron-circle" v-if="collapseArrSec[goalItemIndex]"></i>
                                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                                </button>
                                            </td>
                                            <td colspan="4"><template v-if="goalItem!==null">{{ goalItem.name_ru }}</template></td>
                                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                        </tr>
                                        <template v-if="!collapseArrSec[goalItemIndex]">
                                            <template v-for="(elementItem, elementItemIndex) of forecastFormArr">
                                                <template v-if="elementItem!==null && elementItem.goalsId===goalItem.id && elementItem.programId ===targetItem.id">
                                                    <tr :key="`targetItemIndx_${targetItemIndx}_${goalItemIndex}_${elementItemIndex}`">
                                                        <td colspan="3"></td>
                                                        <td>{{ elementItem.targetIndicators }}</td>
                                                        <td style="text-align: left">
                                                            <ul class="listABP">
                                                                <template v-for="(abps, abpIndex) of elementItem.abpList">
                                                                    <li :key="`abpIndex_${abpIndex}`">{{ abps.item }}</li>
                                                                </template>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: left">
                                                            <ul class="listBP">
                                                                <template v-for="(bps, bpIndex) of elementItem.bpList">
                                                                    <li :key="`bpIndex_${bpIndex}`">{{ bps.item }}</li>
                                                                </template>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: center">{{ elementItem.unitFromStat }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearOne!==null ? elementItem.yearOne.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearTwo!==null ? elementItem.yearTwo.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearThree!==null ? elementItem.yearThree.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearFour!==null ? elementItem.yearFour.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearFive!==null ? elementItem.yearFive.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearSix!==null ? elementItem.yearSix.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearSeven!==null ? elementItem.yearSeven.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: center">
                                                            <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                                                <template v-slot:button-content>
                                                                    <i class="icon icon-more"></i>
                                                                </template>
                                                                <template>
                                                                <b-dropdown-item @click="editClk(elementItem)">
                                                                    Редактировать
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="addClk()">
                                                                    Добавить
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="deleteClk(elementItem)">
                                                                    Удалить
                                                                </b-dropdown-item>
                                                                </template>
                                                            </b-dropdown>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        <tr class="row-fixed">
                            <td style="text-align: center;">&sum;</td>
                            <td style="text-align: center;">{{ totalSGP }}</td>
                            <td style="text-align: center;">{{ totalGoals }}</td>
                            <td style="text-align: center;">{{ totalGoalIndicators }}</td>
                            <td style="text-align: center;"> {{ totalABP }}</td>
                            <td style="text-align: center;">{{ totalBP }}</td>
                            <td style="text-align: center;" colspan="8"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!--------------------------Компонент Тимура - для добавления/редактирования------------------->
        <div v-show="edit">
            <c-forecast-edit :currentForecast="currentForecast" @closeEdit="edit=false" @saved="saved"/>
        </div>
        <!-------------------------------------------------------------->
    </div>
</template>

<script>
import CForecastEdit from '@/modules/forecast/ForecastEdit.vue';
import Multiselect from 'vue-multiselect'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: "ForecastFormNew",
    components: {
        'multiselect': Multiselect,
        'c-forecast-edit': CForecastEdit
    },
    created() {
        this.currentYear = new Date().getFullYear();
        // this.currentInputYear = new Date().getFullYear();
        const currentYear = this.currentYear;
        // console.log('currentYear: ' + currentYear)
        this.tableFieldYearOne = parseInt(currentYear) - 2;
        this.tableFieldYearTwo = parseInt(currentYear) - 1;
        this.tableFieldYearThree = parseInt(currentYear);
        this.tableFieldYearFour = parseInt(currentYear) + 1;
        this.tableFieldYearFive = parseInt(currentYear) + 2;
        this.tableFieldYearSix = parseInt(currentYear) + 3;
        this.tableFieldYearSeven = parseInt(currentYear) + 4;

        this.loadProgram();
        this.loadProgramGoals();
        this.loadAbp();
        this.currentYear = new Date().getFullYear();

        this.$watch('collapseAll', this.collapseAllFunc);
    },
    computed: {

    },
    mounted() {
        const currentYear = this.sendCurrentYear();
        this.listOfYears(currentYear);
    },
    data() {
        return {
            all: {
                name_ru: 'Все',
                name_en: 'All',
                name_kk: 'Барлық',
                id: ''
            },
            yearItem: 'Год',
            currentYear: null,
            tableFieldYearOne: null,
            tableFieldYearTwo: null,
            tableFieldYearThree: null,
            tableFieldYearFour: null,
            tableFieldYearFive: null,
            tableFieldYearSix: null,
            tableFieldYearSeven: null,
            collapseAll: true,
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            yearsArr: [],
            programArr: [],
            abpArr: [],
            dictProgramVal: null,
            dictProgramGoalVal: null,
            dictAbpVal: null,
            sgpDoc: [
                {
                    name_ru: 'Документы СГП',
                    name_kk: 'Документы СГП',
                    name_en: 'SGP Documents'
                },
                {
                    name_ru: 'Цели',
                    name_kk: 'Цели',
                    name_en: 'Goals'
                },
                {
                    name_ru: 'АБП',
                    name_kk: 'АБП',
                    name_en: 'ABP'
                },
            ],
            addRecords: {
                name_ru: 'Добавить',
                name_en: 'Add',
                name_kk: 'Добавить'
            },
            programGoalArr: [],
            storeAbpArr: [],
            forecastFormArr: [],
            mainGridObjectArr: [],
            programObjArr: [],
            goalObjArr: [],
            storeForEditAndDelete: [],
            edit: false,
            headParams: null,
            currentForecast: null,
            openMore: false,
            bar: 0,
            totalSGP: 0,
            totalGoals: 0,
            totalGoalIndicators: 0,
            totalABP: 0,
            totalBP: 0
        }
    },
    methods: {
        //----Передача параметров из шапки с фильтрами----//
        chgParams() {   // передаются параметры фильтра на гриду
            const chosenYear = this.currentYear;
            this.tableFieldYearOne = parseInt(chosenYear) - 2;
            this.tableFieldYearTwo = parseInt(chosenYear) - 1;
            this.tableFieldYearThree = parseInt(chosenYear);
            this.tableFieldYearFour = parseInt(chosenYear) + 1;
            this.tableFieldYearFive = parseInt(chosenYear) + 2;
            this.tableFieldYearSix = parseInt(chosenYear) + 3;
            this.tableFieldYearSeven = parseInt(chosenYear) + 4;

            this.headParams = { year: this.currentYear, program: this.dictProgramVal, goal: this.dictProgramGoalVal, abp: this.dictAbpVal };
            const data = this.headParams;
            this.bar = 20;
            // console.log('data: ' + JSON.stringify(data));
            this.getFieldsOfTable(data);
        },
        collapseAllFunc() {
            // console.log('this.collapseAll: ' + this.collapseAll);
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItems(targetItemIndx, programId) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            // this.directArrSingle = [];
            // console.log('targetItemIndx: ' + targetItemIndx + ' programId: ' + programId);
            const rowIndex = targetItemIndx;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // console.log('collapseArr: ' + JSON.stringify(this.collapseArr));
        },
        collapseItemsSec(targetItemIndx, directionId) {  // ---------Раскрытие третьего уровня при нажатии на стрелку вниз
            // console.log('directionId: ' + directionId);
            const rowIndex = targetItemIndx;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // console.log('collapseArrSec: ' + JSON.stringify(this.collapseArrSec));
        },
        collapseItemsThird(goalItemIndx, goalId) {  // ---------Раскрытие четвертого уровня при нажатии на стрелку вниз
            // console.log('goalId: ' + goalId);
            const rowIndex = goalItemIndx;
            this.collapseArrThird[rowIndex] = !this.collapseArrThird[rowIndex];
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
            // console.log('collapseArrThird: ' + JSON.stringify(this.collapseArrThird));
        },
        //-----Извлекается массив со всеми данными для грид таблицы----//
        async getFieldsOfTable(header){
            this.bar = 45;
            const result = [];
            let response = [];
            if (header !== ''){
                this.forecastFormArr = [];
                this.mainGridObjectArr = [];
                this.storeForEditAndDelete = [];
                //----Извлекаются параметры фильтра для построения гриды----//
                const years = header.year !== null ? parseInt(header.year) : '';
                const programs = (header.program !== null && header.program.id !==undefined) ? header.program.id : '';
                const targets = (header.goal !== null && header.goal.id !== undefined) ? header.goal.id : '';
                const admLevel = header.adm && header.adm.id !== null && header.adm.id !== '' ? header.adm.id : '';
                const abps = header.abp !== null ? header.abp.id : '';
                console.log('year: ' + years + ' program: ' + programs + ' goal: ' + targets + ' abp: ' + abps);
                // await this.getInitialArray(years, programs, targets, abps); // ---- Метод для извлечения первичного массива
                //--------Извлекаются все данные из json согласно параметрам-----//
                try {
                    this.bar = 50;
                    response = await fetch('/api/stat-dicts/sgp_ti?year=' + years + '&program=' + programs + '&goal=' + targets + '&abp=' + abps);
                    this.bar = 60;
                    response = await response.json();
                    this.bar = 70;
                    // console.log('response: ' + JSON.stringify(response));
                    if (response.length > 0) {
                        for (const el of response) {
                            if (el.program !== null && el.goals !== null && ((el.years !== null && el.years.length>0) && el.indicator !== null)) {
                                // console.log('indicator: ' + JSON.stringify(el.indicator) + ' year: ' + JSON.stringify(el.years));
                                result.push(el);
                                this.storeForEditAndDelete.push(el); // ---Массив для хранения первичных данных
                                this.bar = 90;
                            }
                        }
                        // console.log('result: ' + JSON.stringify(result));
                    }
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки таблицы ЦИ', error.toString());
                }
                if (targets !== '') {
                    const enternalArr = this.extractInternalQueryArr(result, targets, admLevel);
                    this.objectPreparation(enternalArr);
                    // console.log('enternalArr: ' + JSON.stringify(enternalArr));
                } else {
                    this.objectPreparation(result);
                    // console.log('extractInternalQueryArr Не отработал! ' + JSON.stringify(result));
                }
            }
            this.bar = 100;
        },
        // ----------Создается основной массив с фильтрацией (которая ранее обращалась в БД) для грид таблицы-------//
        objectPreparation(arr) {
            this.forecastFormArr = [];
            this.mainGridObjectArr = [];

            if (arr.length > 0) {
                for (const el of arr) {
                    const elem = this.getForecastElementsByParams(el); // ----Извлекаются объекты строк массива для гриды
                    this.forecastFormArr.push(elem);
                    // ----
                    this.mainGridObjectArr.push(el);// Массив с объектами для извлечения массивов с объектами из "Program" и "Goals"
                }
            }
            this.preparationForGrid();// ---Создаются массивы с объектами без дубликатов из "Program" и "Goals"
        },

        // ------Построение объектов строк гриды----//
        getForecastElementsByParams(elements) {
            let el = {};
            let yearFactOne = '';
            let yearFactTwo = '';
            let yearPlanOne = '';
            let yearPlanTwo = '';
            let yearPlanThree = '';
            let yearPlanFour = '';
            let yearPlanFive = '';

            const chosenYear = this.sendCurrentYear();  // ----Год выбранный из фильтра---//
            if (elements !== null && elements !== '') {
                if (elements.years.length > 0) {
                    if (chosenYear !== null) {
                        // console.log('chosenYear: ' + chosenYear + ' year: '+JSON.stringify(elements.years))
                        // console.log('years: '+years.year+ ' year: '+JSON.stringify(years))
                        // ---------Условия фильтра по годам-------//
                        for (const year of elements.years) {
                            if ((chosenYear - 1) == year.year) {
                                yearFactTwo = year.fact;
                            }
                            if ((chosenYear - 2) == year.year) {
                                yearFactOne = year.fact;
                            }
                            if (chosenYear == year.year) {
                                yearPlanOne = year.plan;
                            }
                            if ((chosenYear + 1) == year.year) {
                                yearPlanTwo = year.plan;
                            }
                            if ((chosenYear + 2) == year.year) {
                                yearPlanThree = year.plan;
                            }
                            if ((chosenYear + 3) == year.year) {
                                yearPlanFour = year.plan;
                            }
                            if ((chosenYear + 4) == year.year) {
                                yearPlanFive = year.plan;
                            }
                        }
                        let abp = '';
                        let bp = '';
                        let noDuplicates = [];
                        let noBpDuplicates = [];
                        let countABP = 0;
                        let countBP = 0;
                        if (elements.abps && elements.abps.length > 0) {
                            const ulArr = [];
                            const bpUlArr = [];
                            let listOfABP = '<ul class="listABP">';
                            let listOfBP = '<ul class="listBP">';
                            // console.log('elements.abps: ' + JSON.stringify(elements.abps));
                            for (const ab of elements.abps) {
                                const tempArr = {};
                                const bpArr = {};
                                bpArr.id = ab.dictEbkFuncDto.id;
                                bpArr.item = ab.dictEbkFuncDto.prg + ' - ' + ab.dictEbkFuncDto.nameRu;
                                // console.log('ab.dictEbkFuncDto.id: ' + ab.dictEbkFuncDto.id + ' ab.dictEbkFuncDto.abp: ' + ab.dictEbkFuncDto.abp + ' ab.dictEbkFuncDto.nameRu: ' + ab.dictEbkFuncDto.nameRu);
                                bpUlArr.push(bpArr);
                                for (const elAbp of this.storeAbpArr) {
                                    if (ab.abp === elAbp.abp) {
                                        // console.log('ABP: ' + ab.abp);
                                        tempArr.id = elAbp.abp;
                                        tempArr.item = elAbp.abp + ' - ' + elAbp.nameRu;
                                        ulArr.push(tempArr);
                                    }
                                }
                            }
                            // console.log('ulArr: ' + JSON.stringify(ulArr));
                            noDuplicates = this.getOnlyObjects(ulArr);

                            for (const abpEl of noDuplicates) {
                                countABP += 1;
                                listOfABP += '<li>' + abpEl.item + '</li>';
                            }
                            noBpDuplicates = this.getOnlyObjects(bpUlArr);
                            for (const bpEl of noBpDuplicates) {
                                countBP += 1;
                                listOfBP += '<li>' + bpEl.item + '</li>';
                            }

                            listOfABP += '</ul>';
                            listOfBP += '</ul>';
                            abp = listOfABP;
                            bp = listOfBP;
                            // console.log('abp: ' + JSON.stringify(abp));
                        }
                        const idObject = ((elements.id !== '' && elements.id !== null) ? elements.id : '');
                        const programId = ((elements.program !== '' && elements.program !== null) ? elements.program.id : '');
                        const prg = ((elements.program !== '' && elements.program !== null) ? elements.program.name_ru : '');
                        // const directionId = ((elements.direction !== '' && elements.direction !== null) ? elements.direction.id : '');
                        const indicators = ((elements.indicator !== '' && elements.indicator !== null) ? elements.indicator.name_ru : '');
                        const target = ((elements.goals !== '' && elements.goals !== null) ? elements.goals.name_ru : '');
                        const unit = ((elements.statUnit != null && elements.statUnit != '') ? elements.statUnit.nationalSymbol : '');
                        const goalsId = (elements.goals != null) ? elements.goals.id : '';
                        // const macroindicator = ((elements.macroindicator !== '' && elements.macroindicator !== null && elements.macroindicator == true) ? 'M' : '');
                        // const sgpParDoc = ((elements.sgpParentDoc !== '' && elements.sgpParentDoc !== null) ? elements.sgpParentDoc.name_ru : '');

                        el = {
                            id: idObject,
                            programId: programId,
                            sgp: prg,
                            goals: target,
                            targetIndicators: indicators,
                            abp: abp,
                            bp: bp,
                            abpList: noDuplicates,
                            bpList: noBpDuplicates,
                            countABP: countABP,
                            countBP: countBP,
                            unitFromStat: unit,
                            yearOne: yearFactOne,
                            yearTwo: yearFactTwo,
                            yearThree: yearPlanOne,
                            yearFour: yearPlanTwo,
                            yearFive: yearPlanThree,
                            yearSix: yearPlanFour,
                            yearSeven: yearPlanFive,
                            goalsId: goalsId,
                            levels: 3
                        };
                    }
                }
            }
            this.bar = 80;
            return el;
        },
        // -----Дробление массива по объектам для извлечения группированных объектов из справочника "Program" и "Goals"
        preparationForGrid() {
            const programListArr = [];
            const goalListArr = [];
            this.collapseArr = [];
            this.collapseArrSec = [];
            this.collapseArrThird = [];
            // -------------------Подсчёт итоговых показателей--------------------------//
            let sumSGP = 0;
            let sumGoals = 0;
            let sumGoalIndicators = 0;
            let sumABP = 0;
            let sumBP = 0;
            // ----Извлекаются объекты без дубликатов по Программам
            // console.log('this.mainGridObjectArr: ' + JSON.stringify(this.mainGridObjectArr));
            for (const mainEl of this.mainGridObjectArr) {
                if (mainEl.goals !== null) {
                    mainEl.program.isGoal = true;
                } else {
                    mainEl.program.isGoal = false;
                }
                if (mainEl.indicator !== null) {
                    mainEl.program.isIndicator = true;
                } else {
                    mainEl.program.isIndicator = false;
                }
                programListArr.push(mainEl.program);
            }
            this.programObjArr = this.getOnlyObjects(programListArr); // ----Извлечение массива с объектами "Программ" без дубликатов
            if (this.programObjArr.length > 0) {
                for (const programs of this.programObjArr) {
                    this.collapseArr.push(true);
                    sumSGP += 1;
                }
            }
            // console.log('ProgramIsFirstLevel: ' + JSON.stringify(this.programObjArr));
            // ----Извлекаются объекты по Целям
            for (const goalEl of this.mainGridObjectArr) {
                if (goalEl.goals !== null) {
                    goalEl.goals['programId'] = goalEl.program.id;
                    // console.log('goalEl.goals: ' + JSON.stringify(goalEl.goals));
                    goalListArr.push(goalEl.goals);
                }
            }
            this.goalObjArr = this.getOnlyGoalObjects(goalListArr); // ----Извлечение массива с объектами "Целей" без дубликатов
            const goalArr = [];
            if (this.goalObjArr.length > 0) {
                for (const goal of this.goalObjArr) {
                    this.collapseArrSec.push(true);
                    goalArr.push(goal);
                }
            }
            const sumAbpArr = [];
            const sumBpArr = [];
            const sumGoalIndicatorsArr = [];
            if (this.forecastFormArr.length > 0) {   // -----Вкладывается в каждый последний элемент статус true (это значит что последние выпадающий лист закрыт для каждого уровня)
                // console.log('this.forecastFormArr: ' + JSON.stringify(this.forecastFormArr));

                for (const lastLevel of this.forecastFormArr) {
                    this.collapseArrThird.push(true);

                    if (lastLevel.targetIndicators!=null){
                        if (lastLevel.targetIndicators && lastLevel.sumGoalIndicatorsArr!==''){
                            sumGoalIndicatorsArr.push(lastLevel.targetIndicators);
                        }
                        if (lastLevel.abpList!==null){
                            for (const abp of lastLevel.abpList){
                                sumAbpArr.push(abp);
                            }
                        }
                        if (lastLevel.bpList!=null){
                            for (const bp of lastLevel.bpList){
                                sumBpArr.push(bp);
                            }
                        }
                    }
                }
            }

            const arrForCounting = this.getOnlyObjects(goalArr);
            const abpSumArr = this.getOnlyObjects(sumAbpArr);
            const bpSumArr = this.getOnlyObjects(sumBpArr);
            if (arrForCounting.length > 0){
                // console.log('goalSumArr: ' + JSON.stringify(arrForCounting));
                for (const goal of arrForCounting){
                    // console.log('name_ru: ' + goal.name_ru)
                    sumGoals += 1;
                }
            }
            if (sumGoalIndicatorsArr.length > 0){
                const arrForCountingIndicator = this.getOnlyItemsOfObjects(sumGoalIndicatorsArr)
                // console.log('sumGoalIndicatorsArr: ' + JSON.stringify(arrForCountingIndicator));
                for (const goals of arrForCountingIndicator){
                    // console.log('name_ru: ' + goals.name_ru);
                    sumGoalIndicators += 1;
                }
            }
            if (abpSumArr.length>0){
                for (const abps of abpSumArr){
                    // console.log('name_ru: ' + abps.name_ru);
                    sumABP +=1;
                }
            }
            if (bpSumArr.length>0){
                for (const bps of bpSumArr){
                    // console.log('name_ru: ' + bps.name_ru);
                    sumBP +=1;
                }
            }
            this.totalCounter(sumSGP, sumGoals, sumGoalIndicators, sumABP, sumBP);
        },
        // ------Счётчик итоговых показателей----------//
        totalCounter(sumSGP, sumGoals, sumGoalIndicators, sumABP, sumBP) {
            this.totalSGP = sumSGP;
            this.totalGoals = sumGoals;
            this.totalGoalIndicators = sumGoalIndicators;
            this.totalABP = sumABP;
            this.totalBP = sumBP;
            // ----------------------------------========-----------------------------------//
        },
        // ----Извлекаются данные из фильтра: Цели, Адм.уровень
        extractInternalQueryArr(arr, targets, admLevel) {
            // console.log('targets: ' + targets + ' targets.length: ' + targets.length);
            const filteredArr = [];
            if (arr.length > 0) {
                // console.log('Entered: ' + targets);
                for (const init of arr) {
                    // console.log('init: ' + JSON.stringify(init.goals));
                    //----Условия фильтра
                    //----Документы СГП = not null, Целевые индикаторы = not null, АБП = null
                    if ((targets !== '' && targets !== null)) {
                        if (init.goals.id === targets) {
                            // console.log('First_targets_init: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        }

                        /*
                        } else if (admLevel != '' && admLevel != null) {
                            if (init.abps) {
                                const levelIdArr = [];
                                for (const level of init.abps) {
                                    if ((level.dictEbkFuncDto.budgetLevelId !== null) && (level.dictEbkFuncDto.budgetLevelId === admLevel)) {
                                        levelIdArr.push(init);
                                    }
                                }
                                if (levelIdArr.length > 0) {
                                    filteredArr.push(init);
                                }
                            }
                        */
                    }
                }
                // console.log('filteredArr: ' + JSON.stringify(filteredArr));
            }
            return filteredArr;
        },
        //-----Список годов в фильтрации по годам----//
        listOfYears(currentYear){
            this.yearsArr = [];
            if (currentYear!==null){
                for (let i=(currentYear-1); i<=(currentYear+1); i++){
                    this.yearsArr.push(i);
                }
            }
            // console.log('this.yearsArr: ' + JSON.stringify(this.yearsArr));
        },
        // -----Извлекается выбранный год из фильтра----//
        sendCurrentYear() {
            const curYear = this.currentYear;
            // console.log('curYear: ' + curYear);
            return curYear;
        },
        //----Извлекается справочник программ----//
        async loadProgram() {   // ---Извлекается справочник Программ---//
            this.dictProgramGoalVal = '';
            this.dictProgramVal = '';
            let response = [];
            const result = [];
            this.programArr = [];
            const params = this.currentYear;
            if (params !== null && params !== '') {
                try {
                    // response = await fetch('/api/dict/program/');
                    response = await fetch('/api/forecast/year-forecast?year=' + params);
                    response = await response.json();
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки Программ', error.toString());
                }
                if (response.length > 0) {
                    result.push(this.setNameLangs(this.all, 'program'));
                    for (const el of response) {
                        // console.log('el: ' + el.id);
                        result.push(this.setNameLangs(el, 'program'))
                    }
                    // console.log('response: ' + JSON.stringify(result));
                }
                if (this.dictProgramVal !== null) {
                    this.dictProgramVal = this.setNameLangs(this.all, 'program');
                    this.dictProgramVal = this.setNameLangs(this.dictProgramVal, 'program');
                    // console.log('dictProgramVal: ' + JSON.stringify(this.dictProgramVal))
                }
                // console.log('response: ' + JSON.stringify(response));
                this.programArr = result;
                await this.loadProgramGoals();
            }
        },
        //----Отображение справочника "Целей" в зависимости от выбора программ----//
        async loadGoals() {
            let response = [];
            const result = [];
            this.dictProgramGoalVal = '';
            this.programGoalArr = [];   //---массив для справочника Целей
            // console.log('this.dictProgramVal.id: ' + this.dictProgramVal.id);
            if (this.dictProgramVal.id) {
                try {
                    // console.log('this.dictProgramVal.id: ' + this.dictProgramVal.id);
                    response = await fetch('/api/program-passport?programId=' + this.dictProgramVal.id);
                    response = await response.json();
                    if (response.length === 0) {
                        return;
                    }
                } catch (error) {
                    this.makeToast('Information', 'справочник фильтра "Цели" - пуст', error.toString());
                }
                if (Object.keys(response).length !== 0){
                    // console.log('response: ' + JSON.stringify(response));
                    const programPassportGoalsArr = response.programPassportGoals;  //---Извлекается весь подфильтр справочника "Цели" в массив
                    // console.log('Object.keys(response).length: ' + Object.keys(response).length + ' response: ' + JSON.stringify(response) + 'programPassportGoalsArr: ' + JSON.stringify(programPassportGoalsArr));
                    // console.log('response.length: ' + programPassportGoalsArr.length);
                    if (programPassportGoalsArr.length > 0) {
                        result.push(this.setNameLangs(this.all, 'goal'));
                        for (const goals of programPassportGoalsArr) {
                            // console.log('goals: ' + JSON.stringify(goals.dictProgramGoal));
                            result.push(this.setNameLangs(goals.dictProgramGoal, 'goal'));   //---Создается массив с элементом 'goal'
                        }
                        if (this.dictProgramGoalVal !== null && this.dictProgramGoalVal !== '') {    //---должен отображаться выбранный элемент из спрвочника
                            this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
                        }
                        this.programGoalArr = result;   //--Присвоение глобальному массиву весь список после фильтра справочника "Программа"
                    } else { //---Если запрос пуст в базе, то происходит очистка параметров
                        this.dictProgramGoalVal = null;
                    }
                }
            } else {    //--Если Id не передался то очищается глобальный массив и происходит возврат в исходное состояние
                this.programGoalArr = [];
                await this.loadProgramGoals();
            }
        },
        //----Извлекается справочник целей----//
        async loadProgramGoals(){
            // console.log('Checking');
            let response = [];
            const result = [];
            this.programGoalArr = [];
            try {
                response = await fetch('/api/dict/program_goals');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Целей', error.toString());
            }
            if (response.length>0) {
                result.push(this.setNameLangs(this.all, 'goal'));
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'goal'))
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictProgramGoalVal !== null){
                this.dictProgramGoalVal = this.setNameLangs(this.all, 'goal');
                this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
            }
            this.programGoalArr = result;
        },
        //----Извлекается справочник АБП------//
        async loadAbp(){
            let response = [];
            const result = [];
            this.abpArr = [];
            this.storeAbpArr = [];
            try {
                // response = await fetch('/api-py/get-dict-ved-abp');
                response = await fetch('/api/forecast/dict-ebk-func-abp');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
            }
            if (response.length>0) {
                result.push(this.setNameLangs(this.all, 'abp'));
                for (const el of response) {
                    this.storeAbpArr.push(el);
                    result.push(this.setNameLangs(el, 'abp'))
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictAbpVal !== null){
                this.dictAbpVal = this.setNameLangs(this.all, 'abp');
                this.dictAbpVal = this.setNameLangs(this.dictAbpVal, 'abp');
            }
            this.abpArr = result;
        },
        saved(savedObj) {
            // console.log('Сохранённый объект', savedObj);
            if (savedObj && this.headParams!==null){
                this.getFieldsOfTable(this.headParams);
            }
        },
        // ----Создание объектов на основе переданных данных из справочников----//
        setNameLangs(obj, codeName) {
            // console.log('i18nString: ' + this.i18nString);
            // console.log('obj: ' + JSON.stringify(obj) +' codeName: ' + codeName)
            // let txt = obj['name_' + this.$i18n.locale];
            let txt = '';
            if (codeName === 'abp') {
                txt = obj.nameRu;
            } else {
                txt = obj.name_ru;
            }
            // console.log('txt: ' + txt)
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
                // console.log('txt: ' + txt);
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            // console.log('el: ' + JSON.stringify(el));
            return el;
        },
        padLeadingZeros(num, size) {    // -------добавление нулей после значения в зависимости от размера значения
            let s = String(num);
            while (s.length < size) { s = s + '0'; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        editClk(param) {
            // console.log('param: ' + JSON.stringify(param));
            // console.log('param.id: ' + param.id);
            if (param.id){
                const obj = this.extractObjectById(param.id, 'edit');
                // console.log('EditObject: ' + JSON.stringify(obj));
                this.currentForecast = obj;
                this.edit = true;
            }
        },
        addClk(){
            this.edit = true;
            this.currentForecast = null;
        },
        deleteClk(param){
            if (param.id){
                // console.log(param.id)
                const obj = this.extractObjectById(param.id, 'delete');
                if (obj) {
                    this.$bvModal.msgBoxConfirm(
                        'Удалить запись?',
                        {
                            title: 'Подтверждение',
                            size: 'lg',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            okTitle: 'Да',
                            cancelTitle: 'Отмена',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(value => {
                            if (value === true) {
                                // console.log('DeleteObject: ' + JSON.stringify(obj));

                                fetch('/api/forecast?id=' + obj.id, {
                                    method: 'DELETE',
                                })
                                    .then(res => res.text()) // or res.json()
                                    .then(res => {
                                        if (this.headParams!==null){
                                            this.getFieldsOfTable(this.headParams);
                                        }
                                        // console.log(res)
                                    })
                            }
                        })
                        .catch(error => {
                            this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                        });
                }
                // this.edit = true;
            }
        },
        extractObjectById(id, item){
            let itemObject = {};
            if (id){
                if (this.storeForEditAndDelete.length>0){
                    for (const arr of this.storeForEditAndDelete){
                        if (arr.id){
                            if (arr.id === id) {
                                // console.log('id: ' + arr.id + ' indicator: ' + arr.indicatorCustomName);
                                if (item === 'edit'){
                                    itemObject = arr;
                                } else if (item === 'delete'){
                                    itemObject = arr;
                                }
                            }
                        }
                    }
                }
            }
            return itemObject;
        },

        // -----Функция для очистки массива Цели от дубликатов
        getOnlyGoalObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.programId === current.programId);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyItemsOfObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item === current);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // ------Метод для отображения сообщения с ошибками----//
        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>

<style>
.is-hidden {
    display: none !important;
}
.listABP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listABP li{
    margin-bottom: 15px;
}
.listBP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listBP li{
    margin-bottom: 15px;
}
.cellAlign{
    text-align: center;
}
</style>